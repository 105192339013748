import * as React from 'react'
import Layout from '../components/layout'

/////////////////////////
// component classes
/////////////////////////
const UnderConstructionPage = () => (
  <Layout>
    <h1>Under Construction</h1>
  </Layout>
)

export default UnderConstructionPage
